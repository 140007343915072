<template>
    <modal ref="modalAsignarOperador" titulo="Asignar entregas al operador logístico" tamano="modal-xl" no-aceptar adicional="Asignar" @adicional="asignar">
        <div class="row mx-0 align-items-center py-3">
            <div class="col-auto text-general f-18 f-500">
                Entregas programadas para el: {{ envios.fecha | helper-fecha('dddd DD MMMM YYYY') }} {{ envios.horario }}
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-12">
                <el-table
                :data="envios.items"
                header-row-class-name="text-general f-16 f-500"
                style="width: 100%"
                @selection-change="seleccionarPedido"
                >
                    <el-table-column
                    fixed
                    type="selection"
                    width="45"
                    prop="id"
                    value="id"
                    />
                    <el-table-column
                    fixed
                    class-name="text-center"
                    width="120"
                    >
                        <template slot-scope="scope">
                            <div class="row mx-0 justify-content-around">
                                <div class="col px-0">
                                    <el-tooltip v-if="scope.row.nuevo_intento > 1" placement="bottom" content="Nuevo intento de entrega" effect="light">
                                        <i class="icon-history f-20 text-general mx-2" />
                                    </el-tooltip>
                                </div>
                                <div class="col px-0">
                                    <el-tooltip v-if="scope.row.productos_faltantes" placement="bottom" content="Productos faltantes" effect="light">
                                        <i class="icon-package-variant f-20 text-general-red mx-2" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    fixed
                    label="Pedido"
                    width="100"
                    >
                        <template slot-scope="scope">
                            <p>
                                {{ scope.row.id }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Leechero"
                    fixed
                    width="120"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            <p>
                                {{ scope.row.leechero }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="cliente"
                    label="Cliente"
                    class-name="text-center"
                    min-width="180"
                    />
                    <el-table-column
                    prop="cedi"
                    label="Cedis"
                    class-name="text-center"
                    min-width="180"
                    />
                    <el-table-column
                    label="Valor"
                    min-width="100"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            <p>
                                {{ separadorNumero(scope.row.valor_final_pedido) }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="total_peso"
                    min-width="125"
                    label="Peso (Kg)"
                    class-name="text-center"
                    />
                    <el-table-column
                    prop="total_volumen"
                    min-width="140"
                    label="Volumen (m3)"
                    class-name="text-center"
                    />
                </el-table>
            </div>
        </div>
    </modal>
</template>

<script>
import Pedidos from "~/services/pedidos/pedidos-admin-envios";
export default {
    props: {
        envios: {
            type: Object, required: true,
        }
    },
    data(){
        return {
            seleccionados: [],
            buscar: '',
        }
    },
    methods: {
        seleccionarPedido(val){
            this.seleccionados = val;
        },
        async asignar(){
            if (this.seleccionados.length > 0){
                try {
                    let params =  {
                        ids: this.seleccionados,
                    }
                    const {data} = await Pedidos.postAsignar(params)
                    this.notificacion('Mensaje', 'Asignación exitosa!', 'success')
                    this.$emit('consultar');
                    this.toggle()
                } catch (error){
                    this.error_catch(error)
                }
            } else {
                this.notificacion('Atención', 'Debe seleccionar al menos un registro', 'warning')
            }
        },
        toggle(){
            this.$refs.modalAsignarOperador.toggle();
        }
    }
}
</script>
